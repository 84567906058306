import cls from "./NavMenu.module.scss"
import home from "shared/assets/icons/home-24.svg"
import user from "shared/assets/icons/user-24.svg"
import plus from "shared/assets/icons/plus-24.svg"
import friends from "shared/assets/icons/friends.svg"
import { NavButton } from "shared/ui/NavButton"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { navActions } from "../model/slice/navSlice"
import React from "react"

export const NavMenu = () => {
	const location = useLocation()
	const dispatch = useDispatch()
	const newContent = useSelector((state) => state.nav.newContent)
	const id = useSelector((state) => state.auth.id)
	const nickname = useSelector((state) => state.auth.nickname)

	const onActive = () => {
		dispatch(navActions.onActive(location.pathname))
		dispatch(navActions.setNewContent(false))
	}

	return (
		<div className={cls.Navbar}>
			<NavButton
				to="/"
				title={"Моменты"}
				location={location}
				imgsrc={home}
			/>
			<NavButton
				to="/suggest"
				title={"Создать"}
				location={location}
				imgsrc={plus}
			/>
			<NavButton
				to="/friends"
				title={"Друзья"}
				location={location}
				imgsrc={friends}
			/>
			<NavButton
				to={nickname ? `/${nickname}` : `/${id}`}
				title={"Профиль"}
				location={location}
				imgsrc={user}
				newContent={newContent}
				onActive={onActive}
			/>
		</div>
	)
}
