import cls from "./CardButton.module.scss"
import check from "shared/assets/icons/check-32.svg"
import star from "shared/assets/icons/star-32.svg"
import cross from "shared/assets/icons/cross-32.svg"
import { classNames } from "shared/lib/classNames/classNames"
import { memo } from "react"
import box from "shared/assets/icons/box-24.svg"
import message from "shared/assets/icons/message.svg"
import React from "react"

export const CardButton = memo((props) => {
	const { type, action, className, activeButton, currentSpeak } = props

	const buttonEnum = {
		//переделать под enum
		reject: cls.Reject,
		want: cls.Want,
		accept: cls.Accept,
		collections: cls.Collections,
		speak: cls.Speak,
	}

	const iconEnum = {
		reject: cross,
		want: star,
		accept: check,
		collections: box,
		speak: message,
	}

	const mode = {
		[cls.Active]: activeButton === type,
	}

	const modeIcon = {
		[cls.ActiveSpeak]: currentSpeak,
	}

	return (
		<button
			// disabled={true}
			className={classNames(cls.Button, mode, [
				buttonEnum[type],
				className,
			])}
			onClick={action}
		>
			<img
				className={classNames(cls.Icon, modeIcon, [])}
				alt={type}
				src={iconEnum[type]}
			/>
			{type === "want" ? (
				<div className={cls.Text}>В &quot;хочу&quot;</div>
			) : null}
		</button>
	)
})
