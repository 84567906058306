import { Cross } from "shared/ui/Cross"
import cls from "./SubmitWindow.module.scss"
import whaleSubmit from "shared/assets/images/whale5.png"
import { Button } from "shared/ui/Button"
import React from "react"

export const SubmitWindow = (props) => {
	const { onCloseSubmit, antispam } = props

	let titleText = "Опубликуем в ближайшие дни"
	let secondText = "Спасибо за предложку!"
	let textButton = "Ура!"

	if (antispam) {
		titleText = "Не так быстро. Я не успеваю всё запоминать"
		secondText = "Попробуй создать момент немного позже"
		textButton = "Хорошо, зайду позже"
	}

	return (
		<div className={cls.SubmitWindow}>
			<Cross onClose={onCloseSubmit} />
			<img
				src={whaleSubmit}
				className={cls.ImageSubmit}
				alt="submitWhale"
			/>
			<div className={cls.TitleTextSubmit}>{titleText}</div>
			<div className={cls.SecondTextSubmit}>{secondText}</div>
			<Button
				onClick={onCloseSubmit}
				className={cls.SubmitWindowButton}
				text={textButton}
			/>
		</div>
	)
}
