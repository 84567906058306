import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getMessaging, getToken } from "firebase/messaging"
import { modeActions } from "features/modeLogic/model/slice/modeLogicSlice"
import { db } from "app/providers/FirebaseProvider/ui/FirebaseProvider"
import { doc, setDoc } from "firebase/firestore"
import { useNavigate } from "react-router-dom"
import { getUid } from "features/anonymousAuth"
import { getPushPossibility, getPushesGranted } from "features/modeLogic"
import { PushesGranted } from "./PushesGranted/PushesGranted"
import { RequestPush } from "./RequestPush/RequestPush"
import { Subscribe } from "./Subscribe/Subscribe"
import { classNames } from "shared/lib/classNames/classNames"
import { Button } from "shared/ui/Button"
import cls from "./EmptyMomentPage.module.scss"
import whale from "shared/assets/images/whale2.png"
import { Text, TextSize, TextWeight } from "shared/ui/Text/Text"
import React from "react"

export const EmptyMomentPage = () => {
	const pushPossibility = useSelector(getPushPossibility)
	const pushesGranted = useSelector(getPushesGranted)
	const uid = useSelector(getUid)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [permission, setPermission] = useState(null)

	const onRedirectSuggestion = () => {
		navigate("/suggest")
	}

	return (
		<div className={classNames(cls.EmptyMomentPage, {}, [])}>
			<img className={cls.Image} src={whale} alt="whale"></img>
			<Text size={TextSize.XL} weight={TextWeight.BOLD}>
				Моменты закончились
			</Text>
			<Text className={cls.SecondText}>
				Но новые уже готовятся! Создай свою и она появится в этой ленте
			</Text>
			<Button
				className={cls.Button}
				onClick={onRedirectSuggestion}
				text={"Создать свой момент"}
			></Button>
		</div>
	)
}
