import { useNavigate, useParams } from "react-router-dom"
import cls from "./SingleCollectionPage.module.scss"
import {
	Text,
	TextAlign,
	TextColor,
	TextSize,
	TextWeight,
} from "shared/ui/Text/Text"
import { Button } from "shared/ui/Button"
import { useEffect, useRef, useState } from "react"
import { navActions } from "entities/NavMenu"
import { useDispatch, useSelector } from "react-redux"
import {
	authActions,
	getAcceptedMoments,
	getRejectedMoments,
	getWantedMoments,
} from "features/anonymousAuth"
import chevron from "shared/assets/icons/chevron-left-24.svg"
import share from "shared/assets/icons/share-32.svg"
import { Popup } from "shared/ui/Popup/Popup"
import { ColorEnum } from "shared/ui/Button/ui/Button"
import { shareFunc } from "shared/lib/shareFunc/shareFunc"
import Confetti from "react-confetti"
import { fetchOtherProfileData } from "features/otherProfileData/model/services/fetchOtherProfileData"
import { OfferCollectionModal } from "widgets/OfferCollectionModal"
import { Banner } from "widgets/Banner/Banner"
import { ProgressContainer } from "./ProgressContainer/ProgressContainer"
import { useSpring, animated } from "@react-spring/web"
import { classNames } from "shared/lib/classNames/classNames"
import { BlockedCard, Card, sizeEnum } from "entities/Card/ui/Card"
import {
	getSelectCardsInCollection,
	getSelectCardsInOtherCollection,
} from "app/providers/LoadDataProvider/ui/selectors"

const SingleCollectionPage = (props) => {
	const { collection } = props
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()

	if (/^\d+$/.test(id)) {
		// если айди - переводим в number
		id = +id
	}

	useEffect(() => {
		dispatch(navActions.setVisible(false))
	}, [])

	const nickname = useSelector((state) => state.auth.nickname)
	const userId = useSelector((state) => state.auth.id)
	const [confetti, setConfetti] = useState(false)

	useEffect(() => {
		if (id !== nickname) {
			dispatch(fetchOtherProfileData(id))
		}
		if (id !== userId) {
			dispatch(fetchOtherProfileData(id))
		}
	}, [dispatch])

	let readonly = true
	if (id == nickname) {
		// проверка readonly
		readonly = false
	}

	if (id == userId) {
		readonly = false
	}

	const parsedCollections = useSelector(
		userId == id || nickname == id
			? getSelectCardsInCollection
			: getSelectCardsInOtherCollection
	)

	const parsedCollection = JSON.parse(
		JSON.stringify(
			parsedCollections.find((item) => {
				return item.name === collection.name
			})
		)
	)

	const avatar = useSelector(
		userId == id || nickname == id
			? (state) => state.auth.avatar
			: (state) => state.otherProfileData.avatar
	)

	const myAcceptedMoments = useSelector(getAcceptedMoments) // для баннера снизу
	const myWantedMoments = useSelector(getWantedMoments)
	const myRejectedMoments = useSelector(getRejectedMoments)
	const myAllMoments = myAcceptedMoments.concat(
		myWantedMoments,
		myRejectedMoments
	)

	const opacityHeader = useRef(0)

	const [findedCardDataSecondary, setFindedCardDataSecondary] = useState([])
	const [pagArr, setPagArr] = useState([1]) // массив для пагинации

	useEffect(() => {
		setFindedCardDataSecondary(
			parsedCollection.findedCardData.splice(0, 10)
		)
		setPagArr([...parsedCollection.findedCardData])
	}, [parsedCollection.finded.length])

	const changeState = (status, id) => {
		// изменение хочу/сделано
		if (status === "wanted") {
			dispatch(authActions.updateMomentCollection([status, id]))
			findedCardDataSecondary.find((item) => item.id === id).checked =
				"accepted"
		} else if (status === "accepted") {
			dispatch(authActions.updateMomentCollection([status, id]))
			findedCardDataSecondary.find((item) => item.id === id).checked =
				"rejected"
		} else if (status === "rejected") {
			dispatch(authActions.updateMomentCollection([status, id]))
			findedCardDataSecondary.find((item) => item.id === id).checked =
				"wanted"
		}
	}

	useEffect(() => {
		if (!readonly) {
			const col = localStorage.getItem(`${parsedCollection.name}`)
			if (+col !== parsedCollection.accepted.length) {
				// console.log(`noviy lvl y ${parsedCollection.name}`)
				setConfetti(true)
			}
			localStorage.setItem(
				`${parsedCollection.name}`,
				parsedCollection.accepted.length
			)
		}
	}, [])

	// console.log(parsedCollection)

	const scrollHandler = (e) => {
		if (e.target.scrollTop / 180 < 2) {
			opacityHeader.current.style.opacity = e.target.scrollTop / 180
		}
		if (pagArr.length !== 0) {
			// console.log(e.target.scrollHeight - (e.target.scrollTop + window.innerHeight))
			if (
				e.target.scrollHeight -
					(e.target.scrollTop + window.innerHeight) <
				550
			) {
				// console.log("pag")
				setFindedCardDataSecondary([
					...findedCardDataSecondary,
					...pagArr.splice(0, 6),
				])
			}
		}
	}

	// нужен маппер
	let footerButtonText = "Предложить друзьям"
	let footerText = "Хочешь собрать коллекцию с друзьями?"
	let headerButtonText
	let shareIcon = true
	// let popupCup = false
	let popupHeaderText =
		"Вааау! Всего у 2% коллекционеров сделана вся коллекция!"
	// let goaled = false
	let showPopup = false
	let switchShare = false
	if (parsedCollection.max) {
		headerButtonText = footerButtonText = "Поделиться с друзьями"
		switchShare = true
		footerText = false
		// goaled = true
		showPopup = true
		footerText = "Расскажи миру о своем достижении!"
	} else if (
		parsedCollection.finded.length === parsedCollection.idArray.length
	) {
		headerButtonText = footerButtonText = "Сделать с друзьями"
		switchShare = true
		popupHeaderText = "Все моменты коллекции найдены! Сделай их с друзьями!"
		// popupCup = true
		showPopup = true
		footerText = "Хочешь достичь моменты из коллекции с друзьями?"
	} else {
		headerButtonText = "Найти моменты коллекции"
		shareIcon = false
	}

	const navigateMoment = () => {
		navigate("/")
	}

	const navigateCollection = () => {
		navigate(`/${id}`)
	}

	const height = document.body.scrollHeight

	const animProgress = useSpring({
		from: { width: "0%" },
		to: { width: `${parsedCollection.progress}%` },
		delay: 150,
	})

	const progressMods = {
		[cls.progressBarRadius]:
			parsedCollection.progress === 100 ||
			parsedCollection.progress === 0,
		[cls.progressBarDisabled]: parsedCollection.progress === 0,
	}

	const levelMods = {
		[cls.newLevel]: confetti && !readonly,
	}

	return (
		<div onScroll={scrollHandler} className={cls.SingleCollectionPage}>
			<div className={cls.ButtonHeader}>
				<Button
					className={cls.HeaderButton}
					onClick={navigateCollection}
				>
					<img className={cls.Share} src={chevron} alt="chevron" />
				</Button>
				<Button className={cls.HeaderButton} onClick={shareFunc}>
					<img className={cls.Share} src={share} alt="share" />
				</Button>
			</div>
			<div ref={opacityHeader} className={cls.Header}>
				<div className={cls.HeaderWrapper}>
					<Text align={TextAlign.LEFT} weight={TextWeight.BOLD}>
						{parsedCollection.name}
					</Text>
				</div>
			</div>
			{parsedCollection.max ? (
				<div className={cls.BackgroundGradient} />
			) : null}
			<img
				className={cls.Background}
				src={parsedCollection.img}
				alt="background"
			/>
			{(confetti && !readonly && parsedCollection.level !== 0) ||
			(parsedCollection.max && !readonly) ? (
				<div className={cls.Confetti}>
					<Confetti recycle={false} height={height} gravity={0.3} />
				</div>
			) : null}
			<div className={cls.Wrapper}>
				<img className={cls.Avatar} alt="avatar" src={avatar} />
				<div className={cls.Container}>
					<Text
						className={cls.MainText}
						size={TextSize.XL}
						weight={TextWeight.BOLD}
					>
						{parsedCollection.name}
						{/* {goaled ? <img className={cls.Goal} src={goal} alt="goal" /> : null} */}
					</Text>
					<Text className={cls.MainDescr}>
						{parsedCollection.descr}
					</Text>
					<div className={cls.progressWrapper}>
						<div className={cls.progress}>
							<div className={cls.progressText}>
								<Text weight={TextWeight.BOLD}>
									{parsedCollection.max
										? "max"
										: `${parsedCollection.progressCount} / ${parsedCollection.levelGoal}`}
								</Text>
								<Text
									className={cls.progressTextItem}
									size={TextSize.S}
								>
									сделано
								</Text>
							</div>
							<animated.div
								style={animProgress}
								className={classNames(
									cls.progressBar,
									progressMods,
									[]
								)}
							/>
							{/* <div className={cls.progressBarWrapper}>
                                <div style={{ width: `${parsedCollection.progress}%` }} className={classNames(cls.progressBar, progressMods, [])}></div>
                            </div> */}
						</div>
						<div className={cls.progressLevels}>
							{parsedCollection.max ? (
								<Text
									className={classNames(
										cls.levelText,
										levelMods,
										[]
									)}
									weight={TextWeight.SBOLD}
								>
									max уровень
								</Text>
							) : (
								<Text
									className={classNames(
										cls.levelText,
										levelMods,
										[]
									)}
									weight={TextWeight.SBOLD}
								>
									{parsedCollection.level === 0
										? "Начало"
										: `${parsedCollection.level} уровень`}
								</Text>
							)}
							{!parsedCollection.max ? (
								<Text
									weight={TextWeight.SBOLD}
									color={TextColor.TERBLACK}
								>
									{parsedCollection.nextLevelMax
										? "max уровень"
										: `${
												parsedCollection.level + 1
										  } уровень`}
								</Text>
							) : null}
						</div>
					</div>
					{showPopup && !readonly ? (
						<Popup className={cls.Popup} fixed={true}>
							<div className={cls.PopupContainer}>
								<Text
									className={cls.PopupText}
									align={TextAlign.LEFT}
									weight={TextWeight.SBOLD}
								>
									{popupHeaderText}
								</Text>
								{/* {popupCup ? <img className={cls.PopupCup} src={cup} alt="cup"></img> : null} */}
							</div>
						</Popup>
					) : null}
					{readonly ? (
						<Popup className={cls.Popup} fixed={true}>
							<div className={cls.PopupContainer}>
								<Text
									className={cls.PopupText}
									align={TextAlign.LEFT}
									weight={TextWeight.SBOLD}
								>
									{parsedCollection.max
										? "Вааау! Всего у 2% коллекционеров сделана вся коллекция!"
										: "Мечтайте и помогайте друг другу достигать новых высот вместе!"}
								</Text>
								{/* {popupCup ? <img className={cls.PopupCup} src={cup} alt="cup"></img> : null} */}
							</div>
						</Popup>
					) : null}
					{!readonly ? (
						<Button
							className={cls.Button}
							onClick={switchShare ? shareFunc : navigateMoment}
						>
							<div
								className={shareIcon ? cls.ButtonWrapper : null}
							>
								{shareIcon ? (
									<img
										style={{ width: "32px" }}
										src={share}
										alt="share"
									/>
								) : null}
								<Text
									className={
										shareIcon ? cls.ButtonText : null
									}
									weight={TextWeight.SBOLD}
								>
									{headerButtonText}
								</Text>
							</div>
						</Button>
					) : null}
					<Text
						className={cls.AchieveText}
						align={TextAlign.LEFT}
						weight={TextWeight.BOLD}
					>
						Моменты коллекции
					</Text>
					<ProgressContainer parsedCollection={parsedCollection} />
				</div>
			</div>
			<div className={cls.ContainerGrid}>
				<div className={cls.Grid}>
					{findedCardDataSecondary.map((item, i) => {
						if (item.locked) {
							return (
								<Card
									key={i - 1000}
									blocked={BlockedCard.WHITE}
									size={sizeEnum.SMALL}
									text={null}
									image={item.img}
									color={null}
								/>
							)
						} else {
							return (
								<Card
									key={item.id}
									text={item.text}
									image={item.img}
									size={sizeEnum.SMALL}
									status={item.checked}
									color={item.color}
									id={item.id}
									changeState={changeState}
									readonly={readonly}
								/>
							)
						}
					})}
				</div>
			</div>
			{!readonly ? (
				<div className={cls.Footer}>
					{parsedCollection.finded.length !==
					parsedCollection.idArray.length ? (
						<Popup className={cls.Popup} fixed={true}>
							<div className={cls.PopupContainer}>
								<Text
									className={cls.PopupText}
									align={TextAlign.LEFT}
									weight={TextWeight.SBOLD}
								>
									{parsedCollection.finded.length > 0
										? "Находи моменты и достигай их, для повышения уровня коллекции"
										: "Находи моменты и достигай их, для повышения уровня коллекции"}
								</Text>
							</div>
						</Popup>
					) : null}
					{parsedCollection.finded.length !==
					parsedCollection.idArray.length ? (
						<Button
							onClick={navigateMoment}
							className={cls.Button}
							text={"Найти моменты коллекции"}
						/>
					) : null}
					<Text
						className={cls.FooterText}
						size={TextSize.XL}
						weight={TextWeight.BOLD}
					>
						{footerText}
					</Text>
					<Button
						className={cls.FooterButton}
						color={
							parsedCollection.finded.length !==
							parsedCollection.idArray.length
								? ColorEnum.WHITE
								: ColorEnum.GREEN
						}
						onClick={shareFunc}
					>
						<div className={cls.ButtonWrapper}>
							<img className={cls.Icon} src={share} alt="share" />
							<Text
								className={cls.ButtonText}
								weight={TextWeight.SBOLD}
							>
								{footerButtonText}
							</Text>
						</div>
					</Button>
				</div>
			) : null}
			{readonly ? (
				<OfferCollectionModal
					className={
						myAllMoments.length === 0 ? cls.MarginOffer : null
					}
					text={"Собери коллекцию сам и поделись ей с друзьями!"}
					buttonText={"Начать собирать коллекцию"}
				/>
			) : null}
			{myAllMoments.length === 0 && readonly ? (
				<Banner>
					<div className={cls.BannerContainer}>
						<Text
							className={cls.BannerText}
							align={TextAlign.LEFT}
							size={TextSize.S}
							weight={TextWeight.SBOLD}
						>
							Множество твоих достижений уже здесь! Найдем их?
						</Text>
						<Button
							color={ColorEnum.BLUE}
							className={cls.BannerButton}
							text={"Давай"}
							onClick={navigateMoment}
						/>
					</div>
				</Banner>
			) : null}
		</div>
	)
}

export default SingleCollectionPage
