import { useDispatch, useSelector } from "react-redux"
import { Choosebar } from "widgets/Choosebar"
import cls from "./MomentPage.module.scss"
import { EmptyMomentPage } from "./EmptyMomentPage/EmptyMomentPage"
import { useTransition } from "@react-spring/web"
import { Card } from "entities/Card"
import { useSwipeable } from "react-swipeable"
import {
	authActions,
	getAcceptedMoments,
	getArrayCard,
	getDirection,
	getIsLoaded,
	getRejectedMoments,
	getUid,
	getWantedMoments,
} from "features/anonymousAuth"
import { useEffect, useRef, useState } from "react"
import logo from "shared/assets/icons/logo.svg"
import { iconEnum, sizeEnum } from "entities/Card/ui/Card"
import { Popup } from "shared/ui/Popup/Popup"
import { specialActions } from "features/specialData/model/slice/specialSlice"
import {
	getSpecialArrayCard,
	getSpecialCollection,
	getSpecialDirection,
} from "features/specialData"
import { navActions } from "entities/NavMenu"
import { GreetingFloat } from "./GreetingFloat/GreetingFloat"
import { Text, TextSize, TextWeight } from "shared/ui/Text/Text"
import download from "shared/assets/icons/download.svg"
import { getAgent, getPushPossibility } from "features/modeLogic"
import { modeActions } from "features/modeLogic/model/slice/modeLogicSlice"
import telegram from "shared/assets/icons/telegram.svg"
import { useNavigate } from "react-router-dom"
import { InstallFloat } from "./InstallFloat/InstallFloat"
import { SubscribeFloat } from "./SubscribeFloat/SubscribeFloat"
import { PushFloat } from "./PushFloat/PushFloat"
import { getToken } from "firebase/messaging"
import {
	db,
	messaging,
} from "app/providers/FirebaseProvider/ui/FirebaseProvider"
import {
	doc,
	setDoc,
	getDocs,
	writeBatch,
	deleteField,
} from "firebase/firestore"
import { collection as collectionFb } from "firebase/firestore"
import { ProfileFLoat } from "./ProfileFloat/ProfileFloat"
import React from "react"

const MomentPage = (props) => {
	const { mode, installPrompt } = props
	const dispatch = useDispatch()
	const navigate = useNavigate()

	let cardData = useSelector(
		mode === "special" ? getSpecialArrayCard : getArrayCard
	) // если special то другой редюсер
	cardData = cardData.slice().reverse()

	const showedCardData = [...cardData.splice(0, 1)]

	const direction = useSelector(
		mode === "special" ? getSpecialDirection : getDirection
	)
	const isLoaded = useSelector(getIsLoaded)
	const acceptedMoments = useSelector(getAcceptedMoments)
	const wantedMoments = useSelector(getWantedMoments)
	const rejectedMoments = useSelector(getRejectedMoments)
	const agent = useSelector(getAgent)
	const pushPossibility = useSelector(getPushPossibility)
	const uid = useSelector(getUid)
	const userId = useSelector((state) => state.auth.id)
	const nickname = useSelector((state) => state.auth.nickname)

	const specialCollection = useSelector(getSpecialCollection)
	const collection =
		mode === "special"
			? specialCollection
			: acceptedMoments.concat(wantedMoments, rejectedMoments)

	const animCardData = [...showedCardData]

	const [delta, setDelta] = useState([0, 0]) // стейты для анимации свайпа
	const [rotate, setRotate] = useState(0)
	const [transition, setTransition] = useState(0.3)

	const [opacity, setOpacity] = useState(0) // стейты для анимации в предсвайпе
	const [color, setColor] = useState()
	const [icon, setIcon] = useState(null)

	const [activeButton, setActiveButton] = useState(null) // стейт активной кнопки при онбординге
	const [onTouch, setOnTouch] = useState(true) // стейт для повторного вызова анимации если не свайпнули

	const intervalRef = useRef(null) // реф для интервала и его очистки

	const [visible, setVisible] = useState(false) // стейты для кита
	const [speak, setSpeak] = useState(false)

	const [showGreetingFloat, setShowGreetingFloat] = useState() // стейты для пушей
	// let showGreetingFloat = localStorage.getItem('greeting')
	const [showInstallFloat, setShowInstallFloat] = useState(false)
	const [showSubscribeFloat, setShowSubscribeFloat] = useState(false)
	const [showPushFloat, setShowPushFloat] = useState(false)
	const [showProfileFloat, setShowProfileFloat] = useState(false)

	// const newProfileContent = localStorage.getItem('newProfileContent')

	useEffect(() => {
		// если коллекция пуста - вызов интервала онбординга + очистка интервала при размонтировании
		if (
			showGreetingFloat === false &&
			isLoaded &&
			onTouch &&
			collection.length === 0
		) {
			startInterval()
			return () => {
				clearInterval()
			}
		}
	}, [isLoaded, onTouch, showGreetingFloat])

	useEffect(() => {
		// окно приветствия и показа навбара
		dispatch(navActions.setVisible(true))

		if (!localStorage.getItem("greeting")) {
			setShowGreetingFloat(true)
		}
	}, [])

	useEffect(() => {
		// окно установки
		// console.log(collection.length)
		if (
			collection.length === 10 ||
			collection.length === 30 ||
			(collection.length % 60 === 0 && collection.length !== 0)
		) {
			setShowInstallFloat(true)
		}
	}, [collection.length])

	useEffect(() => {
		// окно подписки
		if (collection.length === 40) {
			if (!localStorage.getItem("subscribeFloat")) {
				setShowSubscribeFloat(true)
			}
		}
	}, [collection.length])

	useEffect(() => {
		// окно пушей
		const time = +new Date()
		const showedTime = localStorage.getItem("showPush")
		const pushesActive = localStorage.getItem("pushesActive")
		// console.log(pushPossibility, !pushesActive)

		if (
			collection.length >= 20 &&
			(time > Date.parse(showedTime) || !showedTime) &&
			!pushesActive &&
			pushPossibility
		) {
			setShowPushFloat(true)
			let nextDay = new Date()
			nextDay.setDate(nextDay.getDate() + 1)
			localStorage.setItem("showPush", nextDay)
		}
	}, [])

	const closePushFloat = () => {
		setShowPushFloat(false)
	}

	const closeGreetingFloat = () => {
		setShowGreetingFloat(false)
		localStorage.setItem("greeting", true)
	}

	const closeProfileFloat = () => {
		setShowProfileFloat(false)
	}

	const animation = (num) => {
		// анимации онбординга
		setTransition(0.6)
		let config
		num === 1
			? (config = [[100, 0], 5, 1, "#DCFFA2", iconEnum.ACCEPT, "accept"])
			: num === 3
			? (config = [[0, -100], 0, 1, "#FFD88D", iconEnum.WANT, "want"])
			: (config = [
					[-100, 0],
					-5,
					1,
					"#F1F1F1",
					iconEnum.REJECT,
					"reject",
			  ])

		setDelta(config[0])
		setRotate(config[1])
		setOpacity(config[2])
		setColor(config[3])
		setIcon(config[4])
		setActiveButton(config[5])
	}

	const clear = () => {
		setOpacity(0)
		setDelta([0, 0])
		setRotate(0)
		setActiveButton(null)
		setIcon(null)
	}

	const startInterval = () => {
		// онбординг интервалом
		let anim = 0

		const switcher = () => {
			if (anim === 6) anim = 0
			anim++
			switch (anim) {
				case 1:
					return animation(1)
				case 2:
					return clear()
				case 3:
					return animation(3)
				case 4:
					return clear()
				case 5:
					return animation()
				case 6:
					return clear()
				default:
			}
		}
		intervalRef.current = setInterval(switcher, 700)
	}

	const clearInterval = () => {
		// функция очистки интервала онбординга
		clearTimeout(intervalRef.current)
		intervalRef.current = null
	}

	const clearOnBoard = () => {
		//ф-ция очистки онбординг стилей, если человек нажмет кнопку а не свайпнет
		setDelta([0, 0])
		setRotate(0)
		setOpacity(0)
		setActiveButton(null)
	}

	const timeoutWhaleRef = useRef(null) // реф для таймаута говорящего кита
	const onSpeak = (speak) => {
		// ф-ция говорящего кита
		if (speak) {
			clearTimeout(timeoutWhaleRef.current)
			timeoutWhaleRef.current = null
			setSpeak(speak)
			setVisible(true)
			timeoutWhaleRef.current = setTimeout(() => {
				setVisible(false)
			}, 4000)
		}
	}

	const requestPermission = () => {
		// const vapidKey = 'BPU52K1eZZIUT4Pio2iZtB_aszomMCBacfcrG_-biGUjVaznDwPrOgskBSddGskKk9BvxQ4_9Zkk-KfeggX5mjo'
		// // console.log('Requesting permission...');
		// Notification.requestPermission().then((permission) => {
		//     if ("serviceWorker" in navigator) {
		//         navigator.serviceWorker
		//             .register("/firebase-messaging-sw.js")
		//             .then(function (registration) {
		//                 // console.log("Registration successful, scope is:", registration.scope);
		//                 getToken(messaging, { vapidKey: vapidKey, serviceWorkerRegistration: registration })
		//                     .then((currentToken) => {
		//                         if (currentToken) {
		//                             // console.log('current token for client: ', currentToken);
		//                             const ref = doc(db, "Auth", uid);
		//                             setDoc(ref, { token: currentToken }, { merge: true });
		//                         } else {
		//                             // console.log('No registration token available. Request permission to generate one.');
		//                         }
		//                     }).catch((err) => {
		//                         // console.log('An error occurred while retrieving token. ', err);
		//                         getToken(messaging, { vapidKey: vapidKey, serviceWorkerRegistration: registration })
		//                             .then((currentToken) => {
		//                                 if (currentToken) {
		//                                     console.log('current token for client: ', currentToken);
		//                                     const ref = doc(db, "Auth", uid);
		//                                     setDoc(ref, { token: currentToken }, { merge: true });
		//                                 } else {
		//                                     // console.log('No registration token available. Request permission to generate one.');
		//                                 }
		//                             })
		//                     });
		//             })
		//             .catch(function (err) {
		//                 // console.log("Service worker registration failed, error:", err);
		//             });
		//     }
		//     if (permission === 'granted') {
		//         localStorage.setItem('pushesActive', true)
		//         dispatch(modeActions.setPushesGranted(true))
		//         console.log(permission)
		//     } else if (permission === 'denied') {
		//         dispatch(modeActions.setPushesGranted(false))
		//     }
		//     setShowPushFloat(false)
		// }
		// )
	}

	useEffect(() => {
		// окно "го в профиль"
		if (collection.length === 23) {
			const newContent = localStorage.getItem("newProfileContent")
			if (!newContent) {
				setShowProfileFloat(true)
				dispatch(navActions.setNewContent(true))
			}
			// console.log(newProfileContent)
		}
	}, [collection.length])

	// useEffect(() => { // показ на 4ой карточке попап с намеком на коллекции
	//     if (collection.length === 4) {
	//         const newContent = localStorage.getItem('newProfileContent')
	//         if (!newContent) {
	//             onSpeak('Твои коллекции уже собираются) Загляни в профиль!')
	//             dispatch(navActions.setNewContent(true))
	//         }
	//     }
	// }, [collection.length])

	const transitions = useTransition(animCardData, {
		//анимация на кнопках и в конце свайпа
		from: { x: 0, y: 0 },
		enter: { x: 0, y: 0 },
		leave: { x: direction[0], y: direction[1] },
		config: { duration: 300 },
	})

	let config = {
		trackMouse: true,
	}

	const handlers = useSwipeable({
		// свайпы
		onSwipedLeft: (eventData) => {
			if (
				isLoaded &&
				showedCardData.length !== 0 &&
				eventData.deltaX < -80
			) {
				onSpeak(animCardData[0].speak)

				mode === "special" // если спешл то другой диспатч
					? dispatch(
							specialActions.rejectMoment([
								showedCardData[0],
								[-1000, 0],
							])
					  )
					: dispatch(
							authActions.rejectMoment([
								showedCardData[0],
								[-1000, 0],
							])
					  )
			}
		},
		onSwipedRight: (eventData) => {
			if (
				isLoaded &&
				showedCardData.length !== 0 &&
				eventData.deltaX > 80
			) {
				onSpeak(animCardData[0].speak)

				mode === "special"
					? dispatch(
							specialActions.acceptMoment([
								showedCardData[0],
								[1000, 0],
							])
					  )
					: dispatch(
							authActions.acceptMoment([
								showedCardData[0],
								[1000, 0],
							])
					  )
			}
		},
		onSwipedUp: (eventData) => {
			if (isLoaded && showedCardData.length && eventData.deltaY < -80) {
				onSpeak(animCardData[0].speak)

				mode === "special"
					? dispatch(
							specialActions.wantMoment([
								showedCardData[0],
								[0, -1000],
							])
					  )
					: dispatch(
							authActions.wantMoment([
								showedCardData[0],
								[0, -1000],
							])
					  )
			}
		},
		onSwiping: (eventData) => {
			// во время свайпа
			setActiveButton(null)
			if (eventData.dir === "Left") {
				// пресвайп градиент reject
				setOpacity(Math.abs(eventData.deltaX / 150))
				setColor("#F1F1F1")
				setIcon(iconEnum.REJECT)
				setActiveButton("reject")
			} else if (eventData.dir === "Right") {
				// пресвайп градиент accept
				setOpacity(Math.abs(eventData.deltaX / 150))
				setColor("#DCFFA2")
				setIcon(iconEnum.ACCEPT)
				setActiveButton("accept")
			} else if (eventData.dir === "Up") {
				setOpacity(Math.abs(eventData.deltaY / 150)) // пресвайп градиент want
				setColor("#FFD88D")
				setIcon(iconEnum.WANT)
				setActiveButton("want")
			}
			setTransition(0)
			if (isLoaded && showedCardData.length !== 0) {
				setDelta([eventData.deltaX, eventData.deltaY])
				setRotate(eventData.deltaX / 20)
			}
			setOnTouch(false)
		},
		onSwiped: () => {
			// после свайпа
			clearInterval()
			setTimeout(() => {
				setDelta([0, 0])
				setRotate(0)
				setTransition(0.3)
				setOnTouch(true)
				setActiveButton(null)
			}, 200)
			setOpacity(0)
		},
		onTouchStartOrOnMouseDown: () => {
			clearInterval()
			clearOnBoard()
		},
		...config,
	})

	let swipeStyles = {
		// стили карточки меняющиеся при пресвайпе
		transform: `
            translate(${delta[0]}px, ${delta[1]}px) 
            rotate(${rotate}deg)
        `,
		transition: `transform ${transition}s ease-out`,
	}

	let gradient = {
		// стили градиента при пресвайпе
		background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${color} 100%)`,
		opacity: `${opacity}`,
	}

	const onInstall = async () => {
		// dispatch(modeActions.setMode('install'))
		installPrompt.prompt()
		// localStorage.setItem('install', true)
		setShowInstallFloat(false)
	}

	const onSubscribe = () => {
		window.open("https://t.me/TagLife_App")
		navigate("/")
		// eslint-disable-next-line no-restricted-globals
		location.reload()
		setShowSubscribeFloat(false)
		localStorage.setItem("subscribeFloat", true)
	}

	const onCloseInstallFloat = () => {
		setShowInstallFloat(false)
	}

	const onCloseSubscribeFloat = () => {
		setShowSubscribeFloat(false)
	}

	const onRedirectToProfile = () => {
		if (nickname) {
			navigate(`/${nickname}`)
		} else {
			navigate(`/${userId}`)
		}
		dispatch(navActions.setNewContent(false))
	}

	return (
		<>
			<div className={cls.Title}>
				<img className={cls.Logo} src={logo} alt="logo" />
				{agent === "android" && installPrompt ? (
					<div onClick={onInstall} className={cls.DownloadWrapper}>
						<Text
							className={cls.DownloadText}
							size={TextSize.S}
							weight={TextWeight.SBOLD}
						>
							Установить
						</Text>
						<div className={cls.DownloadWrapperImg}>
							<img
								className={cls.Download}
								src={download}
								alt="download"
							/>
						</div>
					</div>
				) : (
					<div onClick={onSubscribe} className={cls.DownloadWrapper}>
						<Text
							className={cls.DownloadText}
							size={TextSize.S}
							weight={TextWeight.SBOLD}
						>
							Наш канал
						</Text>
						<div className={cls.DownloadWrapperImg}>
							<img
								className={cls.Download}
								src={telegram}
								alt="download"
							/>
						</div>
					</div>
				)}
			</div>
			{showGreetingFloat ? (
				<GreetingFloat closeGreetingFloat={closeGreetingFloat} />
			) : null}
			{agent === "android" && installPrompt && showInstallFloat ? (
				<InstallFloat
					onInstall={onInstall}
					closeInstallFloat={onCloseInstallFloat}
				/>
			) : null}
			{showSubscribeFloat ? (
				<SubscribeFloat
					closeSubscribeFloat={onCloseSubscribeFloat}
					onSubscribe={onSubscribe}
				/>
			) : null}
			{/* {showPushFloat ? <PushFloat closePushFloat={closePushFloat} onAcceptPush={requestPermission} /> : null} */}
			{showProfileFloat ? (
				<ProfileFLoat
					closeProfileFloat={closeProfileFloat}
					onRedirectToProfile={onRedirectToProfile}
				/>
			) : null}
			<Popup visible={visible} text={speak} />
			<div className={cls.Container}>
				{animCardData.length ? (
					transitions((style, item) => {
						style = { ...style, ...swipeStyles }
						return (
							<Card
								size={sizeEnum.LARGE}
								style={style}
								text={item.text}
								image={item.img}
								color={item.color}
								id={item.id}
								handlers={handlers}
								gradient={gradient}
								icon={icon}
								userId={item.userId}
							/>
						)
					})
				) : (
					<EmptyMomentPage />
				)}
				{cardData.length
					? cardData.map((item) => {
							return (
								<Card
									key={item.id}
									style={{
										zIndex: "-1",
										opacity: "0",
										animation: "none",
									}}
									size={sizeEnum.LARGE}
									text={item.text}
									image={item.img}
									color={item.color}
									id={item.id}
								/>
							)
					  })
					: null}
			</div>
			{animCardData.length ? (
				<Choosebar
					mode={mode}
					activeButton={activeButton}
					clearInterval={clearInterval}
					clearOnBoard={clearOnBoard}
					onSpeak={onSpeak}
				/>
			) : null}
		</>
	)
}

export default MomentPage
