import { Card } from "entities/Card"
import { Round } from "shared/ui/Round"
import cls from "./GridElement.module.scss"
import { useTransition } from "@react-spring/web"
import { RequestModal } from "widgets/RequestModal"
import { useEffect, useState } from "react"
import { sizeEnum } from "entities/Card/ui/Card"
import { Popup } from "shared/ui/Popup/Popup"
import EmptyCollectionPage from "../../EmptyCollectionPage/EmptyCollectionPage"
import { useDispatch } from "react-redux"
import { authActions } from "features/anonymousAuth"
import { AnimatePresence } from "framer-motion"
import { Text, TextAlign, TextWeight } from "shared/ui/Text/Text"
import { OfferCollectionModal } from "widgets/OfferCollectionModal"

//фильтрацию коллекции и анимацию в
//отдельную функцию

const GridElement = (props) => {
	const { collection, switcher, readonly } = props
	const dispatch = useDispatch()

	let stateCollection = collection.slice().sort((a, b) => b.time - a.time)

	const [showedCollection, setShowedCollection] = useState([]) // показанная коллекция
	const [pagArr, setPagArr] = useState([1]) // массив для пагинации
	const [disabled, setDisabled] = useState(false)

	useEffect(() => {
		setShowedCollection(stateCollection.splice(0, 10))
		setPagArr([...stateCollection])
	}, [])

	const scrollHandler = (e) => {
		if (pagArr.length !== 0) {
			if (
				e.target.scrollHeight -
					(e.target.scrollTop + window.innerHeight) <
				100
			) {
				console.log("pag")
				setShowedCollection([
					...showedCollection,
					...pagArr.splice(0, 6),
				])
			}
		}
	}

	const changeState = (status, id) => {
		// изменение хочу/сделано
		if (status === "wanted") {
			setDisabled(true)
			setTimeout(() => setDisabled(false), 600)
			showedCollection.find((item) => item.id === id).checked = "accepted"
			dispatch(authActions.updateMoment([status, id]))
			setShowedCollection([
				...showedCollection.filter((card) => card.id !== id),
				...pagArr.splice(0, 1),
			]) //два изменения стейта????
			// setShowedCollection((prevCards) => prevCards.filter((card) => card.id !== id))
		} else if (status === "accepted") {
			setDisabled(true)
			setTimeout(() => setDisabled(false), 600)
			showedCollection.find((item) => item.id === id).checked = "wanted"
			dispatch(authActions.updateMoment([status, id]))
			setShowedCollection([
				...showedCollection.filter((card) => card.id !== id),
				...pagArr.splice(0, 1),
			])
			// setShowedCollection((prevCards) => prevCards.filter((card) => card.id !== id))
		}
	}

	// const changeState2 = (status, id) => { // изменение хочу/сделано
	//     if (status === 'wanted') {
	//         collection.find((item) => item.id === id).checked = 'accepted';

	//         console.log('bilo', status)
	//         console.log('stalo', collection.find((item) => item.id === id).checked)
	//         dispatch(authActions.updateMomentCollection([status, id]))
	//         console.log(collection)

	//         // setShowedCollection([...collection])
	//     } else if (status === 'accepted') {

	//         collection.find((item) => item.id === id).checked = 'rejected';

	//         console.log('bilo', status)
	//         console.log('stalo', collection.find((item) => item.id === id).checked)
	//         dispatch(authActions.updateMomentCollection([status, id]))
	//         console.log(collection)
	//         // setShowedCollection([...collection, collection.find((item) => item.id === id).checked = 'rejected'])
	//         // setShowedCollection([...collection])
	//     } else if (status === 'rejected') {
	//         collection.find((item) => item.id === id).checked = 'wanted';

	//         console.log('bilo', status)
	//         console.log('stalo', collection.find((item) => item.id === id).checked)
	//         dispatch(authActions.updateMomentCollection([status, id]))
	//         console.log(collection)

	//         // setShowedCollection([...collection, collection.find((item) => item.id === id).checked = 'wanted'])
	//         // setShowedCollection([...collection])
	//     }
	// }

	const transitions = useTransition(showedCollection, {
		//анимация
		from: { opacity: 0, transform: "translate3d(0,40px,0)" },
		enter: { opacity: 1, transform: "translate3d(0,0px,0)" },
		leave: { opacity: 0, transform: "translate3d(0,-40px,0)" },
		config: { tension: 320, friction: 20 },
		trail: 100,
	})

	let popupText = "Сколько уже сделано, а сколько всего впереди!"
	if (switcher === "created") {
		popupText = "Чем займемся сегодня?"
	}
	// if (switcher === 'wanted' && readonly) {
	//     popupText = 'Что тебе хотелось бы сделать вместе?'
	// }
	// if (switcher === 'accepted' && readonly) {
	//     popupText = 'Зацени сколько тут всего интересного!'
	// }

	return (
		<div className={cls.Container} onScroll={scrollHandler}>
			{switcher !== "created" ? (
				<Popup fixed={true}>
					<Text
						className={cls.PopupText}
						align={TextAlign.LEFT}
						weight={TextWeight.SBOLD}
					>
						{popupText}
					</Text>
				</Popup>
			) : null}
			<div className={cls.Grid}>
				{transitions((style, item) => {
					if (typeof item == "string") {
						return (
							<Text
								style={{ gridColumn: "span 2" }}
								className={cls.TextTime}
								align={TextAlign.LEFT}
								weight={TextWeight.BOLD}
							>
								{item}
							</Text>
						)
					} else {
						return (
							<Card
								style={{ ...style, gridRow: "span 6" }}
								text={item.text}
								image={item.img}
								size={sizeEnum.SMALL}
								status={item.checked}
								color={item.color}
								id={item.id}
								blocked={item.blocked}
								changeState={changeState}
								disabledRound={disabled}
								readonly={readonly}
								switcher={switcher}
							/>
						)
					}
				})}
			</div>
			{pagArr.length === 0 && !readonly ? (
				<RequestModal switcher={switcher} />
			) : null}
			{pagArr.length === 0 && readonly ? (
				<OfferCollectionModal
					text={"Твои моменты уже в TagLife! Найдем их?"}
					buttonText={"Начать приключение"}
				/>
			) : null}
		</div>
	)
}

export default GridElement
