import { Button } from "shared/ui/Button"
import { Text, TextSize, TextWeight } from "shared/ui/Text/Text"
import cls from "./BottomButton.module.scss"
import { useNavigate } from "react-router-dom"
import whale from "shared/assets/images/whale4.png"

export const BottomButton = (props) => {
	const { readonly } = props

	const navigate = useNavigate()

	const onRedirectToSuggest = () => {
		navigate("/suggest")
	}

	const onRedirectToMain = () => {
		navigate("/")
	}

	if (readonly) {
		return (
			<div className={cls.BottomButton} style={{ marginTop: "39px" }}>
				<img style={{ width: "100px" }} src={whale} alt="whale"></img>
				<Text
					className={cls.TitleText}
					weight={TextWeight.BOLD}
					size={TextSize.XL}
				>
					Твои моменты уже в TagLife! Найдем их?
				</Text>
				<Button
					onClick={onRedirectToMain}
					className={cls.Button}
					text="Начать приключение"
				/>
			</div>
		)
	}

	return (
		<div className={cls.BottomButton}>
			<Text
				className={cls.TitleText}
				weight={TextWeight.BOLD}
				size={TextSize.XL}
			>
				Хочешь увидеть свой момент в TagLife?
			</Text>
			<Button
				onClick={onRedirectToSuggest}
				className={cls.Button}
				text="Создать свой момент"
			/>
		</div>
	)
}
