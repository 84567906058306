import { Button } from "shared/ui/Button"
import cls from "./RequestPage.module.scss"
import clsEditor from "./TextEditor/TextEditor.module.scss"
import help from "shared/assets/icons/help-24.svg"
import thumbsUp from "shared/assets/icons/thumbs-up-green-24.svg"
import { useEffect, useRef, useState } from "react"
import { classNames } from "shared/lib/classNames/classNames"
import {
	addDoc,
	collection,
	limit,
	orderBy,
	query,
	getDocs,
} from "firebase/firestore"
import { db } from "app/providers/FirebaseProvider/ui/FirebaseProvider"
import { useDispatch, useSelector } from "react-redux"
import { SubmitWindow } from "./SubmitWindow/SubmitWindow"
import { TextEditor } from "./TextEditor/TextEditor"
import { FloatModalRequest } from "./FloatModalRequest/FloatModalRequest"
import { navActions } from "entities/NavMenu"
import { Loader } from "shared/ui/Loader"
import plusCircle from "shared/assets/icons/plus-circle.svg"
import { GifFloat } from "widgets/GifFloat/GifFloat"

const RequestPage = () => {
	const dispatch = useDispatch()

	const [openText, setOpenText] = useState(false)
	const [text, setText] = useState("")
	const [counter, setCounter] = useState(60)
	const [warning, setWarning] = useState("")
	const [accept, setAccept] = useState(false)
	const [submit, setSubmit] = useState(false)
	const [antispam, setAntispam] = useState(false)

	const [disable, setDisable] = useState(false)

	const [openFloatModal, setOpenFloatModal] = useState(false)
	const [openFloatImage, setOpenFloatImage] = useState(false)

	const [imageArray, setImageArray] = useState([])
	const [pagCounter, setPagCounter] = useState(0)
	const [loadingFetchResult, setLoadingFetchResult] = useState(false)
	const [img, setImg] = useState("")
	const [onChangeValue, setOnChangeValue] = useState("")

	// const uid = useSelector(getUid)
	const id = useSelector((state) => state.auth.id)

	// открытие боттомшита

	// useEffect(() => {
	//     let request = localStorage.getItem('request')
	//     if (!request) {
	//         setOpenFloatModal(true)
	//     }
	// }, [])

	useEffect(() => {
		dispatch(navActions.setVisible(true))
	}, [])

	const fetchTenor = (search) => {
		const API_KEY = "AIzaSyAfSZt9dGylsggCKj-zRNBlng-eToO825s"
		// const API_URL = 'https://api.tenor.com/v1/search?q=excited&key=' + API_KEY;

		var clientkey = "TagLife"
		var lmt = 50
		// var pos = 10
		// var search_term = "excited";
		var searchUrl =
			"https://tenor.googleapis.com/v2/search?q=" +
			search +
			"&key=" +
			API_KEY +
			"&client_key=" +
			clientkey +
			"&limit=" +
			lmt +
			"&pos=" +
			pagCounter

		fetch(searchUrl, {
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				// Обрабатываем полученные данные
				setImageArray(data.results)
				setLoadingFetchResult(false)
				setPagCounter((prevState) => prevState + lmt)
			})
			.catch((error) => {
				// Обрабатываем ошибку
				console.error("Error fetching data from Tenor API", error)
			})
	}

	const placeholder = "Нажми, чтобы добавить текст"

	const onOpenTextEditor = () => {
		setCounter(60 - text.length)
		setOpenText(true)
	}

	const onClose = () => {
		setOpenText(false)
		setWarning("")
		setText("")
		setAccept(false)
	}

	const onCloseTextEditor = () => {
		const counter = document.getElementById("counter")
		if (text.length === 0) {
			setOpenText(false)
			setAccept(false)
			setWarning("")
		} else if (text.length < 5) {
			setWarning(". Давай подробнее напишем? ")
			counter.classList.add(clsEditor.Warning)
			counter.classList.add(cls.WarningShake)
			setTimeout(() => {
				counter.classList.remove(cls.WarningShake)
			}, 300)
		} else if (text.length > 60) {
			setWarning(". Давай укоротим?")
			counter.classList.add(clsEditor.Warning)
			counter.classList.add(cls.WarningShake)
			setTimeout(() => {
				counter.classList.remove(cls.WarningShake)
			}, 300)
		} else {
			setOpenText(false)
			counter.classList.remove(clsEditor.Warning)
			setAccept(true)
		}
		dispatch(navActions.setVisible(true))
	}

	const onChangeText = (e) => {
		const counter = document.getElementById("counter")
		setText(e.target.value)
		setCounter(60 - e.target.value.length)
		if (
			warning &&
			e.target.value.length >= 5 &&
			e.target.value.length <= 60
		) {
			setWarning("")
			counter.classList.remove(clsEditor.Warning)
		}
	}

	const onOpenFloatModal = () => {
		setOpenFloatModal(true)
	}

	const onCloseFloatModal = () => {
		setOpenFloatModal(false)
		localStorage.setItem("request", true)
	}

	const submitFunc = () => {
		setDisable(true)
		const ref = collection(db, "suggests")
		const q = query(ref, orderBy("id", "desc"), limit(1))
		let lastId
		getDocs(q)
			.then((snapshot) => {
				lastId = snapshot.docs[0].data().id + 1
			})
			.then(() => {
				const requestsRef = collection(db, "suggests")
				addDoc(requestsRef, {
					id: lastId,
					text: text,
					userId: id,
					img: img,
				})
			})
			.then(() => {
				setAccept(false)
				setText("")
				setSubmit(true)
				setImg("")
				setDisable(false)
			})
	}

	const onSubmit = (text) => {
		if (text.length === 0) {
			setOpenText(true)
		} else if (accept) {
			const suggestCount = JSON.parse(
				localStorage.getItem("suggestCount")
			) // антиспам
			if (!suggestCount) {
				localStorage.setItem(
					"suggestCount",
					JSON.stringify([1, new Date()])
				) // если человек не отправлял ничего в предложку
				submitFunc()
			} else if (suggestCount[0] === 20) {
				const date = new Date()
				date.setMinutes(date.getMinutes() + 10)

				localStorage.setItem(
					"suggestCount",
					JSON.stringify([suggestCount[0] + 1, date])
				) //если отправил уже 5ый раз, ставим дату на 10 минут позже
				submitFunc()
			} else if (suggestCount[0] === 21) {
				const dateNow = new Date()
				if (dateNow > new Date(suggestCount[1])) {
					localStorage.setItem(
						"suggestCount",
						JSON.stringify([1, new Date()])
					) // если 10 минут прошло, обновляем дату и ставим счетчик обратно на 1
					setAntispam(false)
					submitFunc()
				} else {
					// если 10 минут не прошло
					setAntispam(true)
					setSubmit(true)
				}
			} else {
				const date = new Date()
				const delta = date - new Date(suggestCount[1])
				if (delta > 300000) {
					localStorage.setItem(
						"suggestCount",
						JSON.stringify([1, new Date()])
					) // если отправил с перерывом в 5 минут, счетчик скидываеттся
					submitFunc()
					onClearForm()
				} else {
					localStorage.setItem(
						"suggestCount",
						JSON.stringify([suggestCount[0] + 1, new Date()])
					) // если отправил меньше 5 раз
					submitFunc()
					onClearForm()
				}
			}
		}
	}

	const onCloseSubmit = () => {
		setSubmit(false)
	}

	const modeCard = {
		[cls.AcceptCard]: accept,
	}

	const onCloseFloatImage = () => {
		setOpenFloatImage(false)
	}

	// const onChangeRef = useRef(null)
	// const setFocus = () => { onChangeRef.current && onChangeRef.current.focus() }
	const onOpenFLoatImage = () => {
		setOpenFloatImage(true)
		// setFocus()
		if (text) {
			fetchTenor(text)
		}
	}

	const timeoutTenorRef = useRef(null)
	// const onChangeRef = useRef(null)

	const onChangeSearch = (e) => {
		clearTimeout(timeoutTenorRef.current)
		timeoutTenorRef.current = null
		// onChangeRef.current = e.target.value
		setOnChangeValue(e.target.value)
		setLoadingFetchResult(true)
		timeoutTenorRef.current = setTimeout(() => {
			fetchTenor(e.target.value)
		}, 1000)
	}

	const parser = (text) => {
		// Выделение жирным первого слова текста
		const first = text.split(" ")[0]
		const another = text.split(" ")
		another.shift()

		return [first, another.join(" ")]
	}

	const pickImage = (url) => {
		setImg(url)
		setOpenFloatImage(false)
	}

	let parsedText
	accept && text.length !== 0
		? (parsedText = parser(text))
		: (parsedText = parser(placeholder))

	// const scrollHandler = (e) => {

	//     // console.log(e.target.scrollHeight - (e.target.scrollTop + window.innerHeight))
	//     // console.log(e.target.scrollHeight - (e.target.scrollTop + window.innerHeight))
	//     if (e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 100 && imageArray.length < 50) {
	//         console.log('pag')
	//         // setFindedCardData2([...findedCardData2, ...pagArr.splice(0, 6)])
	//         fetchTenor(text)
	//     }
	// }
	// const setFocus = (input) => {
	//     setTimeout(() => { input && input.focus() }, 3000)
	//     // input && input.focus()
	// }

	const onClearForm = () => {
		setOnChangeValue("")
		setImageArray([])
	}

	return (
		<>
			{openFloatImage ? (
				<GifFloat
					onChangeValue={onChangeValue}
					onChangeSearch={onChangeSearch}
					loadingFetchResult={loadingFetchResult}
					onClearForm={onClearForm}
					onCloseFloatImage={onCloseFloatImage}
					imageArray={imageArray}
					pickImage={pickImage}
					searchText={text}
				/>
			) : null}
			{openFloatModal ? (
				<FloatModalRequest onCloseFloatModal={onCloseFloatModal} />
			) : null}
			<div className={cls.RequestPage}>
				{submit ? (
					<SubmitWindow
						onCloseSubmit={onCloseSubmit}
						antispam={antispam}
					/>
				) : null}
				{openText ? (
					<TextEditor
						onClose={onClose}
						onCloseTextEditor={onCloseTextEditor}
						counter={counter}
						warning={warning}
						onChangeText={onChangeText}
						text={text}
					/>
				) : null}
				<div className={cls.TitleContainer}>
					<div className={cls.Title}>Предложи момент</div>
					<div
						onClick={onOpenFloatModal}
						className={cls.HelpContainer}
					>
						<img src={help} className={cls.Help} alt="help" />
					</div>
				</div>
				<div className={classNames(cls.CardContainer, modeCard, [])}>
					{accept && img ? (
						<img
							src={thumbsUp}
							alt={thumbsUp}
							className={cls.ThumbsUp}
						/>
					) : null}
					<div onClick={onOpenTextEditor} className={cls.TextInput}>
						<b>{parsedText[0]}</b> {parsedText[1]}
					</div>
					{!img ? (
						<div
							className={cls.ImageContainer}
							onClick={onOpenFLoatImage}
						>
							<img
								src={plusCircle}
								className={cls.Image}
								alt="whale"
							/>
							<div className={cls.Text}>Добавить GIF</div>
						</div>
					) : (
						<img
							className={cls.PickedImage}
							onClick={onOpenFLoatImage}
							src={img}
							alt="img"
						/>
					)}
				</div>
				<Button
					onClick={() => onSubmit(text)}
					className={cls.Button}
					text={!disable ? "Создать" : null}
					disabled={disable}
				>
					{disable ? <Loader className={cls.Loader} /> : null}
				</Button>
			</div>
		</>
	)
}

export default RequestPage
